import Vue from 'vue';
import Vuex from 'vuex';
import merge from 'lodash/merge';
import manage from './base/manage';
import user from './modules/user';
import userCustomers from './modules/submodules/userCustomers';
import userDoctors from './modules/submodules/userDoctors';
import patient from './modules/patient';
import patientList from './modules/patientlist';
import patientSearch from './modules/patientsearch';
import userList from './modules/userList';
import roles from './modules/roles';
import permissions from './modules/permissions';
import doctors from './modules/doctors';
import apiversion from './modules/apiversion';
import logging from './modules/logging';
import message from './modules/message';
import preopdata from './modules/preopdata';
import cart from './modules/cart';
import orders from './modules/orders';
import preopreport from './modules/preopreport';
import iodreport from './modules/iodreport';
import zone from './modules/zone';
import customers from './modules/customers';
import inventory from './modules/inventory';
import session from './modules/session';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        app: {
            namespaced: true,
            state: {
                showSpinner: false,
                spinnerCount: 0, // Number of active spinner requests
                noapi: false,
            },
            mutations: {
                setSpinner(state, value) {
                    if (value) {
                        state.spinnerCount++;
                    } else {
                        state.spinnerCount = Math.max(0, state.spinnerCount - 1);
                    }

                    // Show spinner if there are any active spinner requests.
                    // Prevents spinner from disappearing too soon.
                    state.showSpinner = state.spinnerCount > 0;
                },
                setNoAPI(state, val) {
                    state.noapi = val;
                },
            },
        },
        userList: merge(manage('users'), userList),
        customers: merge(manage('customers'), customers),
        doctors: merge(manage('doctors'), doctors),
        roles: merge(manage('roles'), roles),
        permissions: merge(manage('permissions'), permissions),
        orders: merge(manage('orders'), orders),
        reservations: merge(manage('orders'), {namespaced: true}),
        inventory,
        user: {
            ...user,
            modules: {
                userCustomers: merge(manage('users/me/customers'), userCustomers),
                userDoctors: merge(manage('users/me/doctors'), userDoctors),
            },
        },
        patient,
        patientList,
        patientSearch,
        apiversion,
        logging,
        message,
        preopdata,
        preopreport,
        iodreport,
        cart,
        zone,
        session,
    },
});
