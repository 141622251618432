const state = () => ({
    idleExpiryTimestamp: null,
});

const getters = {
    idleExpiryTimestamp: (state) => state.idleExpiryTimestamp,
};

const mutations = {
    setIdleExpiryTimestamp(state, timestamp) {
        state.idleExpiryTimestamp = timestamp;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
};
