<template>
    <div>
        <div
            class="idle-timer-container"
            :class="timeRemainingSeconds <= 60 ? 'text-red' : 'text-gray-dark'"
            id="idletimer"
        >
            <b-icon icon="hourglass-bottom" class="mr-1" />
            <span class="time-remaining-string">{{ timeRemainingString }}</span>
        </div>
        <b-tooltip
            target="idletimer"
            :title="$t('idleTimerTooltip', {timeRemaining: formatTimeRemaining()})"
        />
    </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
    name: 'IdleTimer',
    data() {
        return {
            timeRemainingSeconds: 999, // Just set to a high number to start so color is gray by default
            timeRemainingString: '00:00',
        };
    },
    computed: {
        ...mapGetters({
            idleExpiryTimestamp: 'session/idleExpiryTimestamp',
        }),
    },
    methods: {
        // Calculates the difference between the stored expiry timestamp (in total seconds) and now
        calculateTimeLeftInSeconds() {
            if (!this.idleExpiryTimestamp) return 0;
            const currentTimeInSeconds = Math.floor(Date.now() / 1000);
            const diff = this.idleExpiryTimestamp - currentTimeInSeconds;
            return diff > 0 ? diff : 0;
        },

        // Formatted as mm:ss
        formatTimeRemaining() {
            const totalSeconds = this.calculateTimeLeftInSeconds();
            this.timeRemainingSeconds = totalSeconds;
            const minutes = Math.floor(totalSeconds / 60);
            const seconds = totalSeconds % 60;
            return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
        },
    },
    mounted() {
        // Set the starting time remaining, and then update every second with the interval
        this.timeRemainingString = this.formatTimeRemaining();

        this.interval = setInterval(() => {
            this.timeRemainingString = this.formatTimeRemaining();
        }, 1000);
    },
    beforeDestroy() {
        clearInterval(this.interval);
    },
};
</script>

<style scoped>
.idle-timer-container {
    min-width: 60px;
}

.time-remaining-string {
    cursor: default;
}
</style>
